import {
  BOOK_BUTTON_WIDGET_ID,
  DAILY_TIMETABLE_WIDGET_ID,
  STAFF_LIST_WIDGET_ID,
} from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';

export const MEMBERS_AREA_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const BOOKINGS_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
export const ECOM_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
export const MY_WALLET_DEF_ID = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';
export const PRICING_PLANS_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export enum WidgetsId {
  BOOKINGS_LIST_PAGE = '621bc837-5943-4c76-a7ce-a0e38185301f',
  OLD_BOOKINGS_SERVICE_LIST = '13d27016-697f-b82f-7512-8e20854c09f6',
  BOOKINGS_LIST_WIDGET = 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
  CALENDAR_PAGE = '54d912c5-52cb-4657-b8fa-e1a4cda8ed01',
  CALENDAR_WIDGET = '0eadb76d-b167-4f19-88d1-496a8207e92b',
  SERVICE_PAGE = 'a91a0543-d4bd-4e6b-b315-9410aa27bcde',
  FORM_PAGE = '985e6fc8-ce3f-4cf8-9b85-714c73f48695',
  WEEKLY_TIMETABLE = '3c675d25-41c7-437e-b13d-d0f99328e347',
  DAILY_AGENDA = 'e86ab26e-a14f-46d1-9d74-7243b686923b',
  MEMBERS_BOOKINGS_WIDGET = '14edb332-fdb9-2fe6-0fd1-e6293322b83b',
}

export enum PageId {
  BOOKINGS_LIST = 'bookings_list',
  BOOKINGS_CHECKOUT = 'book_checkout',
  SCHEDULER = 'scheduler',
  BOOKINGS_MEMBERS_AREA = 'bookings_member_area',
  MY_WALLET = 'my_wallet',
  BOOKINGS_SERVICE_PAGE = 'Booking Service Page',
  BOOKINGS_CALENDAR_PAGE = 'Booking Calendar',
  BOOKINGS_FORM_PAGE = 'Booking Form',
}

export enum MigrationFailReason {
  STYLES = 'styles',
  PAGES = 'pages',
  DATA = 'data',
}

export const pagesToPromptMigrationModal = [
  PageId.BOOKINGS_LIST,
  PageId.BOOKINGS_SERVICE_PAGE,
  PageId.BOOKINGS_CALENDAR_PAGE,
  PageId.BOOKINGS_CHECKOUT,
];

export const experiments = {
  SKIP_MEMBER_INSTALLATION: 'specs.bookings.Editor2SkipMemberInstallation',
  CART_ENABLED: 'specs.bookings.cart',
  ADDITIONAL_TIME_SLOTS_IN_FORM_PLUGIN:
    'specs.bookings.AdditionalTimeSlotsInFormPlugin',
  BOOKINGS_CALENDAR_APP_REFLOW: 'specs.bookings.viewerScript.CalendarAppReflow',
  BOOKINGS_SERVICE_PAGE_APP_REFLOW:
    'specs.bookings.viewerScript.ServicePageAppReflow',
  BOOKINGS_TRANSLATE_PAGES: 'specs.bookings.translatePages',
  BOOKINGS_UNINSTALL: 'specs.bookings.UninstallBookings',
  APP_REFLOW_ON_RESPONSIVE: 'specs.bookings.viewerScript.AppReflowOnResponsive',
  ALLOW_EDIT_CART_POPUP: 'specs.bookings.AllowEditCartPopup',
  SHOW_MEMBER_AREA_INSTALLATION_MODAL:
    'specs.bookings.ShowMemberAreaInstallationModal',
  USE_NEW_BO_LINKS_IN_UOU: 'specs.bookings.isUseNewBOLinksInUoUEnabled',
};

export const PETRI_SCOPE = 'bookings-viewer-script';
export const DAILY_TIMETABLE_WIDGET_CONTROLLER_ID = DAILY_TIMETABLE_WIDGET_ID;
export const BOOK_BUTTON_WIDGET_CONTROLLER_ID = BOOK_BUTTON_WIDGET_ID;
export const STAFF_LIST_WIDGET_CONTROLLER_ID = STAFF_LIST_WIDGET_ID;

export type HandleActionPayload = {
  withPricingPlans?: boolean;
  OOIMigration?: boolean;
  CartMigration?: boolean;
  emptyScript?: boolean;
  appDefinitionId?: string;
};

export const FEDOPS_APP_NAME = 'bookings-viewer-script';

export enum EcomMigrationServerlessStatus {
  SELF_MIGRATION_RUNNING = 'SELF_MIGRATION_RUNNING',
  SELF_MIGRATION_SUCCESS = 'SELF_MIGRATION_SUCCESS',
  SELF_MIGRATION_FAILED = 'SELF_MIGRATION_FAILED',
}

export const IS_CART_ENABLED_CUSTOM_PROPERTY = 'isCartEnabled';

export const PAGE_URI_SEO_SUFFIX = '-statics';

export const PAGE_ROLES = {
  BOOKING_CALENDAR: 'bookingCalendar',
  SERVICE_PAGE: 'bookingServicePage',
};
